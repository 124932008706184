import { PageRendererProps } from "gatsby"
import React, { ReactNode } from "react"
import styled from '@emotion/styled'
import { TopNavigation } from "./TopNavigation"
import { Container } from "./Container"
import { Footer } from "./Footer"
import { CTA } from "./CTA"

interface Props extends PageRendererProps {
  title: string
  children: ReactNode
}

const Content = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Main = styled.main`
  flex: 1;
  max-width: 750px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
`


export const Layout = (props: Props) => {
  const { children } = props

  return (
    <Content>
      <TopNavigation />
      <Container>
        <Main>{children}</Main>
      </Container>
      <CTA />
      <Footer />
    </Content>
  )
}
