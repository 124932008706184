import React from "react"
import { theme } from "./styles"
import { css } from "@emotion/core"

export const menuLinkStyles = css`
  position: relative;
  color: ${theme.colors.gray};
  text-decoration: none;
  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out, bottom 0.2s ease-in-out, height 0.2s ease-in-out;
  }
  @media (hover: hover) {
    &:hover {
      color: ${theme.colors.lightPurple};
      &::before {
        height: 3px;
        bottom: -5px;
        background-color: ${theme.colors.lightPurple};
      }
    }
    &.active:hover::before {
      height: 3px;
      bottom: -5px;
      background-color: ${theme.colors.lightPurple};
    }
  }
  &.active {
    color: ${theme.colors.lightPurple};
  }
`

interface Props {
  active?: boolean
  children: React.ReactNode
  to: string
}

export const MenuLink = (props: Props) => (
  <a css={menuLinkStyles} href={props.to} className={props.active ? 'active' : ''}>
    {props.children}
  </a>
)
