import React from "react"
import { css } from "@emotion/core"

interface Props {
  size: number
  open: boolean
  color: string
  className?: string
}

export const Hamburger = (props: Props) => {
  const hambuderStyles = css`
    display: inline-block;
    width: ${props.size}px;
    height: ${props.size}px;
    margin: 0;
    padding: 0;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: ${props.size / 5}px;
      width: 100%;
      background: ${props.color};
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
    span:nth-of-type(1) {
      top: 0px;
    }
    span:nth-of-type(2) {
      top: ${(props.size / 5) * 2}px;
    }
    span:nth-of-type(3) {
      top: ${(props.size / 5) * 4}px;
    }
  `

  const openStyles = css`
    span:nth-of-type(1) {
      top: ${(props.size / 5) * 2}px;
      transform: rotate(135deg);
    }
    span:nth-of-type(2) {
      opacity: 0;
      left: ${props.size * 2}px;
    }
    span:nth-of-type(3) {
      top: ${(props.size / 5) * 2}px;
      transform: rotate(-135deg);
    }
  `

  return (
    <div css={[hambuderStyles, props.open && openStyles]} className={props.className}>
      <span />
      <span />
      <span />
    </div>
  )
}
