import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Hamburger } from "./Hamburger"
import Link from "gatsby-link"
import logo from "./logo.svg"
import { Container } from "./Container"
import { mediaQueries, theme } from "./styles"
import { MenuLink, menuLinkStyles } from "./MenuLink"

const Navbar = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0;
  ${mediaQueries.large(
    css`
      align-items: center;
      padding: 40px 0;
    `
  )};
`

const LargeNavbarLinks = styled("ul")`
  display: none;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  ${mediaQueries.large(
    css`
      display: flex;
    `
  )}
  li {
    align-self: center;
  }
  li:not(:first-of-type) {
    margin-left: 30px;
  }
`

const SmallNavbarMenu = styled("div")`
  display: flex;
  text-align: right;
  flex-direction: column;
  ${mediaQueries.large(
    css`
      display: none;
    `
  )};
`

const SmallNavbarButton = styled("button")`
  color: ${theme.colors.gray};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  appearance: none;
  border: 0;
  background-color: transparent;
  padding: 0;
  &:focus {
    outline: 0;
  }
`

const SmallNavbarButtonText = styled("span")`
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  ${menuLinkStyles};
`

const SmallNavbarLinks = styled("ul")`
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  li {
    align-self: center;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  &.visible {
    opacity: 1;
    max-height: 300px;
  }
`

const Logo = styled("img")`
  display: block;
  max-width: 165px;
`

interface State {
  menuShown: boolean
}

export class TopNavigation extends React.PureComponent<any, State> {
  state = { menuShown: false }

  render() {
    return (
      <Container>
        <Navbar>
          <Link to="https://www.nazavody.cz">
            <Logo alt="naZavody.cz" width={165} height={25} src={logo} />
          </Link>
          <LargeNavbarLinks>{this.renderItems()}</LargeNavbarLinks>
          <SmallNavbarMenu>
            <SmallNavbarButton onClick={this.toggleMenu}>
              <SmallNavbarButtonText>{this.state.menuShown ? "Zavřít" : "Menu"}</SmallNavbarButtonText>
              <Hamburger size={20} open={this.state.menuShown} color={theme.colors.gray} />
            </SmallNavbarButton>
            <SmallNavbarLinks className={this.state.menuShown ? "visible" : ""}>{this.renderItems()}</SmallNavbarLinks>
          </SmallNavbarMenu>
        </Navbar>
      </Container>
    )
  }

  private renderItems() {
    return (
      <>
        <li>
          <MenuLink to="https://nazavody.cz/vsechny-zavody">Všechny závody</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/pro-organizatory">Pro organizátory</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/pro-zavodniky">Pro závodníky</MenuLink>
        </li>
        <li>
          <MenuLink active to="/">Blog</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/kontakt">Kontakt</MenuLink>
        </li>
      </>
    )
  }

  private toggleMenu = () => {
    this.setState({ menuShown: !this.state.menuShown })
  }
}
