import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { mediaQueries, theme } from "./styles"
import { MenuLink } from "./MenuLink"
import { Container } from "../components/Container"

const FooterLinkRow = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 32px;
  margin-top: 40px;
  padding: 16px 0;
  border-top: 1px solid ${theme.colors.lightGray};
  ${mediaQueries.large(
    css`
      margin-top: 100px;
      padding: 32px 0;
    `
  )};
`

const FooterLinks = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    align-self: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  ${mediaQueries.large(css`
    flex-direction: row;
    li {
      margin-right: 30px;
    }
  `)};
`

const FooterCopyRow = styled("div")`
  color: ${theme.colors.gray};
`

export const Footer = () => (
  <Container>
    <FooterLinkRow>
      <FooterLinks>
        <li>
          <MenuLink to="https://nazavody.cz/vsechny-zavody">Všechny závody</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/pro-organizatory">Pro organizátory</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/pro-zavodniky">Pro závodníky</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/podminky">Obchodní podmínky</MenuLink>
        </li>
        <li>
          <MenuLink active to="https://blog.nazavody.cz">Blog</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/novinky">Novinky</MenuLink>
        </li>
        <li>
          <MenuLink to="https://www.nazavody.cz/kontakt">Kontakt</MenuLink>
        </li>
      </FooterLinks>
      <FooterCopyRow>naZavody.cz s.r.o. &copy; {new Date().getFullYear()}</FooterCopyRow>
    </FooterLinkRow>
  </Container>
)
