import { css } from "@emotion/core"
import React from "react"
import { mediaQueries } from "./styles"

interface Props {
  children: any
  className?: string
}

export const Container = (props: Props) => {
  const containerStyles = [
    css`
      max-width: 1240px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 16px;
      padding-right: 16px;
    `,
    mediaQueries.small(css`
      padding-left: 32px;
      padding-right: 32px;
    `)
  ]

  return (
    <section className={props.className}>
      <div css={containerStyles}>{props.children}</div>
    </section>
  )
}
