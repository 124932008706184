import { css } from "@emotion/core"

const baseSize = 14

export const theme = {
  colors: {
    successGreen: "#7ac142",
    lightPurple: "#9F3EF5",
    purple: "#58099E",
    easyGray: "#efefef",
    lightGray: "#CCCCCC",
    gray: "#3E3E3E",
    black: "#000000",
    white: "#ffffff",
    red: "#ef4035",
    gradient: "linear-gradient(45deg, #9013FE 0%, #9F3EF5 100%)"
  },
  fontSizes: {
    bigger: baseSize * 1.1428571429 + "px", // 16px
    base: baseSize + "px", // 14px
    small: baseSize * 0.8571428571 + "px" // 12
  },
  headingsMobile: {
    h1: baseSize * 2.1428571429 + "px", // 30px
    h2: baseSize * 1.7142857143 + "px", // 24px
    h3: baseSize * 1.4285714286 + "px", // 20px
    h4: baseSize * 1.2857142857 + "px", // 18px
    h5: baseSize * 1.1428571429 + "px", // 16px
    h6: baseSize + "px" // 14px
  },
  headings: {
    h1: baseSize * 2.8571428571 + "px", // 40px
    h2: baseSize * 2.1428571429 + "px", // 30px
    h3: baseSize * 1.7142857143 + "px", // 24px
    h4: baseSize * 1.4285714286 + "px", // 20px
    h5: baseSize * 1.1428571429 + "px", // 16px
    h6: baseSize + "px" // 14px
  },
  breakPoints: {
    base: 0,
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200
  },
  paddings: {
    small: "4px",
    medium: "12px",
    big: "16px",
    biggest: "32px"
  },
  shadows: {
    card: css`
      box-shadow: 0 0.6px 1.1px rgba(0, 0, 0, 0.031), 0 1.3px 2.7px rgba(0, 0, 0, 0.044), 0 2.5px 5px rgba(0, 0, 0, 0.055), 0 4.5px 8.9px rgba(0, 0, 0, 0.066), 0 8.4px 16.7px rgba(0, 0, 0, 0.079),
        0 20px 40px rgba(0, 0, 0, 0.11);
    `
  }
}

const andMaxWidth = (max?: number) => (max ? ` and (max-width: ${max}px)` : "")

export const mediaQueries = {
  base: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 0px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  small: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 576px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  medium: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 768px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  large: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 992px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  extraLarge: (innerCss: any) =>
    css`
      @media (min-width: 1200px) {
        ${innerCss};
      }
    `
}
