import React from "react"
import { css } from "@emotion/core"
import { theme, mediaQueries } from "../components/styles"

export const Paragraph: React.FC<Props> = ({ centered, children, className, narrow }) => (
  <p
    css={[
      css`
        font-size: ${theme.fontSizes.base};
        margin-top: 0;
        margin-bottom: ${theme.paddings.medium};
        line-height: 1.56;
        ${mediaQueries.large(
          css`
            font-size: ${theme.fontSizes.bigger};
          `
        )};
      `,
      centered &&
        css`
          text-align: center;
        `,
      narrow &&
        css`
          max-width: ${theme.breakPoints.small}px;
        `
    ]}
    className={className}
  >
    {children}
  </p>
)

interface Props {
  narrow?: boolean
  centered?: boolean
  className?: string
  children: React.ReactNode
}
