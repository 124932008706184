import { css } from "@emotion/core"
import React from "react"
import { mediaQueries, theme } from "../components/styles"
import { Container } from "./Container"
import styled from "@emotion/styled"
import { app2x } from "./Images"
import { Paragraph } from "./Paragraph"

const Row = styled("div")`
  ${mediaQueries.large(
    css`
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      margin-left: -20px;
      margin-right: -30px;
    `
  )};
`

const ColText = styled("div")`
  ${mediaQueries.large(
    css`
      max-width: 40%;
      flex: 0 0 40%;
      padding-left: 20px;
      padding-right: 30px;
    `
  )};

  h2 {
    font-size: 30px;
    margin-top: 1.3em;
    margin-bottom: 0.5em;
    line-height: 1.2;
    ${mediaQueries.large(
      css`
        font-size: 40px;
    `
  )};
  }
`

const ColImage = styled("div")`
  margin-top: 30px;
  ${mediaQueries.large(
    css`
      max-width: 60%;
      flex: 0 0 60%;
      margin-top: 0;
      padding-left: 20px;
      padding-right: 30px;
    `
  )};
`

const AppImage = styled("img")`
  border-radius: 4px;
  ${theme.shadows.card}
`

const Button = styled("a")`
  display: inline-block;
  padding: 10px 22px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
  background: none;
  color: ${theme.colors.purple};
  border: 1px solid ${theme.colors.purple};
  &:hover {
    background-color: #8a30f6;
    color: ${theme.colors.white};
  }
`

export const CTA = () => (
  <Container
    css={css`
      padding-top: 30px;
      ${mediaQueries.large(
        css`
          padding-top: 100px;
        `
      )};
    `}
  >
    <Row>
      <ColText>
        <h2>
          Pořádáte závod? <br />
          Můžeme vám pomoci
        </h2>
        <Paragraph
          css={css`
            margin-top: 20px;
            margin-bottom: 20px;
            ${mediaQueries.large(
              css`
                max-width: 330px;
                margin-top: 35px;
                margin-bottom: 35px;
              `
            )};
          `}
        >
          Služba <a href="https://www.nazavody.cz/pro-organizatory">naZávody.cz</a> je registrační systém přizpůsobený pro závody a sportovní akce. Správa startovních listin, automatické kategorie, statistiky,
          párování plateb a mnoho dalšího. Zjednodušení práce organizátorům a snadné přihlašování pro závodníky.
        </Paragraph>
        <Button href="https://www.nazavody.cz/pro-organizatory">
          Více informací
        </Button>
      </ColText>
      <ColImage>
        <AppImage src={app2x} alt="Administrační prostředí" />
      </ColImage>
    </Row>
  </Container>
)
